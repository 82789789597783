<template>
<div class="table-box">
  <div class="table-container">
    <el-row :gutter="24">
     <el-col :span="24">
         <!-- <el-row class="floatLeft margin">
			开始日期：
                <el-date-picker @change="starHorizon" v-model="chooseMsg.starTime" type="date" placeholder="选择日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd">
                </el-date-picker>
        </el-row>
		<el-row class="floatLeft margin">
			结束日期：
            <el-date-picker @change="endHorizon" v-model="chooseMsg.starTime" type="date" placeholder="选择日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd">
            </el-date-picker>
        </el-row> -->
		<el-row class="floatLeft margin">
			<p>操作用户：</p>
            <el-select  @ clearable v-model="chooseMsg.user" placeholder="请选择">
                <el-option v-for="item in userList" :key="item.id" :label="item.nickName" :value="item.userName">
                </el-option>
            </el-select>
        </el-row>
		<el-row class="floatLeft margin">
            <p>操作类型：</p>
            <el-select @change="selectChange(chooseMsg.ActionType)" @ clearable v-model="chooseMsg.ActionType" placeholder="请选择">
                <el-option v-for="item in logType" :key="item" :label="item" :value="item">
                </el-option>
            </el-select>
        </el-row>
        <el-table
          id="out-table"
          ref="singleTable"
          stripe
          :data="logList"
          highlight-current-row
          style="width:100%;"
          border
        >
            <el-table-column prop="creatusernickname" label="用户名">
            </el-table-column>
            <el-table-column prop="actiontype" label="操作类型">
            </el-table-column>
            <el-table-column prop="creattimestr" label="操作时间">
            </el-table-column>
            <el-table-column prop="actiondescribe" label="描述">
            </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
  </div>
</template>
<script>

export default {
  name: 'User',
  data () {
    return {
      logList: [],
      logListTemp: [],
      userList: [],
      logType: [],
	    chooseMsg: {
        starTime: '',
        endTime: '',
        user: '',
        ActionType: '登录'
      },
      searchValue: ''
    }
  },
  created () {
    this.getLogs(this.chooseMsg)
    this.getType()
  },
  methods: {
    getLogs (form) {
      this.$axios.post('/api/SystemOther/GetLogList?pageIndex=1&pageSize=10', form).then(res => {
        // console.log('res',res)
        this.logList = res.mg_SystemLogDto
        this.logListTemp = res.mg_SystemLogDto
      })
    },
    getType () {
      this.$axios.get('/api/User/GetUsers').then(res => {
        this.userList = res
      })
      this.$axios.get('/api/SystemOther/GetLogTypes').then(res => {
        this.logType = res
      })
    },
    search () {
      var str = this.searchValue
      // console.log(this.logListTemp)
      if (str == '') {
        this.logList = this.logListTemp
        return
      }
      this.logList = this.logListTemp.filter(n => {
        return n.userName.indexOf(str) > -1 || n.nickName.indexOf(str) > -1
      })
    },
    // 开始时间
    starHorizon () {
      // 第二个参数1代表开始时间
      // this.isChoose.isChooseStartTime = !!this.starTime

      this.$refs.pagination.parallelSearch(Object.values(this.chooseMsg))
    },
    // 结束时间
    endHorizon () {
      // 第二个参数2代表结束事件
      // this.isChoose.isChooseEndTime = !!this.endTime
      this.$refs.pagination.parallelSearch(Object.values(this.chooseMsg))
    },
    // 下拉选择时间
    selectChange (val, isChoose) {
      this.getLogs(this.chooseMsg)
      if (val == 'creatorUserName') {
        // this.isChoose.isChoosePerson = isChoose
        // this.$refs.pagination.parallelSearch(Object.values(this.chooseMsg))
      } else {
        // this.isChoose.isChooseType = isChoose
        // this.$refs.pagination.parallelSearch(Object.values(this.chooseMsg))
      }
    }

  }
}
</script>

<style scoped lang="less">
.float {
  float: right;
  margin-bottom: 20px;
}
.float > button {
  margin-left: 10px;
}
.floatLeft {
  float: left;
  margin-bottom: 20px;
//   .item-box {
        display: flex;
        align-items: center;
        width: 300px;

        // background-color: #000000;
        p {
          width: 150px;
          text-align: center;
        }

}
.floatLeft > button {
  margin-left: 10px;
}
.margin {
  margin-right:20px
}
	.table-box {
		padding: 15px 25px;
		height: calc(100vh - 100px);
		box-sizing: border-box;
		background-color: #e7e7e7;
	}

	.table-container {
		display: flex;
		height: calc(100vh - 170px);
		flex-wrap: wrap;
		overflow-y: auto;
		align-content:flex-start;
		box-shadow: 0px 2px 15px 0px rgba(187, 187, 187, 0.5);
		border-radius: 8px;
		background-color: #FFFFFF;
		padding: 20px;
	}
</style>
